<template>
  <div class="clients-modify">
    <v-container class="clients-modify__container" fluid>
      <entity-modify-header class="mb-10" :title="title" />

      <form-builder v-if="!isLoading" :schema="currentSchema" :initial-data="initialData" @submit="submit">
        <template #footer="{ valid }">
          <v-row class="mt-10">
            <v-col>
              <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn type="submit" color="primary" block :disabled="!valid" :loading="isUpload">
                {{ submitButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </v-container>
  </div>
</template>

<script>
// Services
import usersService from '@/services/users';
import analyticsService from '@/services/analytics';

// Components
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Models
import { schema, isEditSchema } from '@/schemas/client.schema';
import { createModelData } from '@/schemas/createModelData';
import notificationService from '@/services/notification';

// Constants
import { CLIENTS, CLIENTS_DETAILED } from '@/constants/routes';
import { CREATE_CLIENT, EDIT_CLIENT } from '@/constants/analyticsActions';

export default {
  name: 'ClientsModify',

  components: { EntityModifyHeader, FormBuilder },

  props: {
    isEdit: { type: Boolean, default: false },
    id: { type: Number, default: null },
    prevPage: { type: Number, default: 1 },
  },

  data() {
    return {
      isLoading: false,
      initialData: this.isEdit ? createModelData(isEditSchema) : createModelData(schema),
      isUpload: false,
    };
  },

  computed: {
    submitButtonText() {
      return this.$t(this.isEdit ? 'button.edit_client' : 'button.add_client');
    },

    backRoute() {
      return this.isEdit
        ? { name: CLIENTS_DETAILED, params: { id: this.id } }
        : { name: CLIENTS, query: { page: this.prevPage || 1 } };
    },

    title() {
      return this.isEdit ? this.$t('client.edit_client') : this.$t('client.add_client');
    },

    currentSchema() {
      return this.isEdit ? isEditSchema : schema;
    },
  },

  mounted() {
    if (this.isEdit) {
      this.isLoading = true;
      usersService
        .getClientById(this.id)
        .then(client => {
          const newClient = { ...client };

          newClient.usages = newClient.usages?.map(usage => {
            const newUsage = { ...usage };

            newUsage.salesContractDate = newUsage.salesContractDate
              ? newUsage.salesContractDate
              : `${newUsage.rentalPeriodStartDate} - ${newUsage.rentalPeriodEndDate}`;

            return newUsage;
          });

          this.initialData = newClient;
        })

        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    submit(data) {
      this.isUpload = true;
      const originData = {};

      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      if (data.firstName) originData.firstName = data.firstName;
      if (data.lastName) originData.lastName = data.lastName;
      if (data.email) originData.email = data.email;
      if (data.phone) originData.phone = data.phone;
      if (data.passport) originData.passport = data.passport;
      if (data.country) originData.country = data.country.value;
      if (data.usages) originData.usages = data.usages;
      originData.isSendPasswordWhenCreating = data.isSendPasswordWhenCreating;

      originData.usages = originData.usages?.map(usage => {
        const OWNER = 'owner';
        const newUsage = { ...usage };

        delete newUsage.building;
        delete newUsage.project;

        newUsage.unit = newUsage.unit.id;

        if (newUsage.clientType !== OWNER) {
          const dates = newUsage.salesContractDate?.split(' - ');
          newUsage.salesContractDate = null;
          [newUsage.rentalPeriodStartDate, newUsage.rentalPeriodEndDate] = dates;
        } else {
          newUsage.rentalPeriodStartDate = null;
          newUsage.rentalPeriodEndDate = null;
        }

        return newUsage;
      });

      const saveClient = this.isEdit ? usersService.updateClientById : usersService.addClient;
      analyticsService.track(this.isEdit ? EDIT_CLIENT : CREATE_CLIENT);

      saveClient({ ...originData, clientId: this.id })
        .then(client => {
          notificationService.success(this.isEdit ? this.$t('client.edited') : this.$t('client.created'), 2000);
          this.$router.push({ name: CLIENTS_DETAILED, params: { id: client.id || this.id } });
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
          );
        })
        .finally(() => {
          this.isUpload = false;
        });
    },
  },

  notificationItem: null,
};
</script>

<style lang="scss">
.clients-modify {
  &__container {
    max-width: 548px !important;
  }
}
</style>
